import React from 'react';
import { get } from 'mori';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { trackEvent } from 'common/analytics';
import { ButtonDropdown, ButtonDropdownLink } from 'components/common/ButtonDropdown';
import { AddSingle, AddMultiple, CalendarAdd, Plus, Voucher, People } from 'components/common/Icon';

const lang = Wahanda.lang.calendar.buttons.addDropdown;

function getDropdownItems(handlers): ButtonDropdownLink[] {
  // Appointment related buttons are shown only if the Appointment handler is present
  const appointmentHandler = get(handlers, 'addAppointment');
  const redeemEvouchers = App.config.canRedeemEvouchers();
  const canEditOwnCalendar = Wahanda.Permissions.editOwnCalendar();
  const hasWaitingList = Wahanda.Features.isEnabled('THY-waiting-list-mvp-enable');

  const redeemEvoucher = () => {
    trackEvent('calendar', 'click', 'redeem-Evoucher');
    const callback = get(handlers, 'redeemEvoucher');
    callback();
  };
  const addAppointment = () => {
    trackEvent('calendar', 'click', 'new-appointment');
    const callback = get(handlers, 'addAppointment');
    callback();
  };
  const addBlock = () => {
    trackEvent('calendar', 'click', 'new-block');
    const callback = get(handlers, 'addBlock');
    callback();
  };
  const startMultipleBlocking = () => {
    trackEvent('calendar', 'click', 'new-block-multiple');
    const callback = get(handlers, 'startMultipleBlocking');
    callback();
  };
  const addWaitingList = () => {
    trackEvent('calendar', 'click', 'new-waiting-list');
    const callback = get(handlers, 'addWaitingList');
    callback();
  };

  if (!appointmentHandler && !canEditOwnCalendar && !redeemEvouchers && !hasWaitingList) {
    return [];
  }

  return [
    appointmentHandler && {
      title: lang.appointment,
      onClick: addAppointment,
      icon: <CalendarAdd />,
    },
    canEditOwnCalendar && {
      title: lang.block,
      onClick: addBlock,
      icon: <AddSingle />,
    },
    canEditOwnCalendar && {
      title: lang.multiBlocks,
      onClick: startMultipleBlocking,
      icon: <AddMultiple />,
    },
    redeemEvouchers && {
      title: lang.evoucher,
      onClick: redeemEvoucher,
      icon: <Voucher />,
    },
    hasWaitingList && {
      title: 'Waiting List',
      onClick: addWaitingList,
      icon: <People />,
    },
  ];
}

interface Props {
  addActions?: {};
}

export const AddDropdown = ({ addActions }: Props) => {
  const items = getDropdownItems(addActions);

  if (!items.length) {
    return null;
  }

  return (
    <div className="add-items-box">
      <ButtonDropdown
        size="small"
        fullWidth
        items={getDropdownItems(addActions)}
        label={lang.title}
        icon={<Plus />}
      />
    </div>
  );
};

AddDropdown.displayName = 'AddDropdown';
