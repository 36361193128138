// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.
//
// WARNING: THE FIRST BLANK LINE MARKS THE END OF WHAT'S TO BE PROCESSED, ANY BLANK LINE SHOULD
// GO AFTER THE REQUIRES BELOW.
//
require('./vendor-globals.js');
require('./app.js');
require('./app-domains.js');
require('./wahanda.js');
require('../../../lib/assets/javascript/libs.js');
require('./backbone/models/appointment.js');
require('./backbone/models/booking.js');
require('./backbone/models/bookings-enquiries.js');
require('./backbone/models/calendar-objects.js');
require('./backbone/models/checkout.js');
require('./backbone/models/config.js');
require('./backbone/models/customer-search.js');
require('./backbone/models/customer.js');
require('./backbone/models/dashboard-reports.js');
require('./backbone/models/employee-category.js');
require('./backbone/models/employee-external-calendar.js');
require('./backbone/models/employee-password-reset.js');
require('./backbone/models/employee.js');
require('./backbone/models/featured-offer.js');
require('./backbone/models/finance-stats.js');
require('./backbone/models/image.js');
require('./backbone/models/mass-email.js');
require('./backbone/models/menu-group.js');
require('./backbone/models/menu-offer.js');
require('./backbone/models/menu-template-item.js');
require('./backbone/models/menu-treatments.js');
require('./backbone/models/menu.js');
require('./backbone/models/microsite.js');
require('./backbone/models/order.js');
require('./backbone/models/pos-checkout.js');
require('./backbone/models/pos-status.js');
require('./backbone/models/products.js');
require('./backbone/models/profile.js');
require('./backbone/models/reference.js');
require('./backbone/models/remittance.js');
require('./backbone/models/review-request.js');
require('./backbone/models/role-permissions.js');
require('./backbone/models/room-allocation.js');
require('./backbone/models/room-availability-saver.js');
require('./backbone/models/room-type.js');
require('./backbone/models/sales-report.js');
require('./backbone/models/spa-allocation.js');
require('./backbone/models/spa-availability-saver.js');
require('./backbone/models/stats-interactions.js');
require('./backbone/models/supplier.js');
require('./backbone/models/time-block.js');
require('./backbone/models/unpaid-remittance.js');
require('./backbone/models/venue.js');
require('./backbone/models/VenueStatistics.js');
require('./backbone/models/WorkingHours.js');
// require('./app-domains.js'); is duplicative.
// require('./app.js'); is duplicative.
false
// require('./vendor-globals.js'); is duplicative.
// require('./wahanda.js'); is duplicative.
require('./backbone/collections/appointment-group.js');
require('./backbone/collections/bookings-enquiries.js');
require('./backbone/collections/bookings.js');
require('./backbone/collections/checkouts.js');
require('./backbone/collections/customer-appointments.js');
require('./backbone/collections/customer-search.js');
require('./backbone/collections/customers.js');
require('./backbone/collections/employee-categories.js');
require('./backbone/collections/employees.js');
require('./backbone/collections/featured-offers.js');
require('./backbone/collections/menu-groups.js');
require('./backbone/collections/menu-items.js');
require('./backbone/collections/menu-offers.js');
require('./backbone/collections/menu-template.js');
require('./backbone/collections/products.js');
require('./backbone/collections/remittances.js');
require('./backbone/collections/room-allocations.js');
require('./backbone/collections/room-types.js');
require('./backbone/collections/spa-allocations.js');
require('./backbone/collections/treatment-types.js');
require('./backbone/collections/treatments.js');
// require('./backbone/models/appointment.js'); is duplicative.
// require('./backbone/models/booking.js'); is duplicative.
// require('./backbone/models/bookings-enquiries.js'); is duplicative.
// require('./backbone/models/calendar-objects.js'); is duplicative.
// require('./backbone/models/checkout.js'); is duplicative.
// require('./backbone/models/config.js'); is duplicative.
// require('./backbone/models/customer-search.js'); is duplicative.
// require('./backbone/models/customer.js'); is duplicative.
// require('./backbone/models/dashboard-reports.js'); is duplicative.
// require('./backbone/models/employee-category.js'); is duplicative.
// require('./backbone/models/employee-external-calendar.js'); is duplicative.
// require('./backbone/models/employee-password-reset.js'); is duplicative.
// require('./backbone/models/employee.js'); is duplicative.
// require('./backbone/models/featured-offer.js'); is duplicative.
// require('./backbone/models/finance-stats.js'); is duplicative.
// require('./backbone/models/image.js'); is duplicative.
// require('./backbone/models/mass-email.js'); is duplicative.
// require('./backbone/models/menu-group.js'); is duplicative.
// require('./backbone/models/menu-offer.js'); is duplicative.
// require('./backbone/models/menu-template-item.js'); is duplicative.
// require('./backbone/models/menu-treatments.js'); is duplicative.
// require('./backbone/models/menu.js'); is duplicative.
// require('./backbone/models/microsite.js'); is duplicative.
// require('./backbone/models/order.js'); is duplicative.
// require('./backbone/models/pos-checkout.js'); is duplicative.
// require('./backbone/models/pos-status.js'); is duplicative.
// require('./backbone/models/products.js'); is duplicative.
// require('./backbone/models/profile.js'); is duplicative.
// require('./backbone/models/reference.js'); is duplicative.
// require('./backbone/models/remittance.js'); is duplicative.
// require('./backbone/models/review-request.js'); is duplicative.
// require('./backbone/models/role-permissions.js'); is duplicative.
// require('./backbone/models/room-allocation.js'); is duplicative.
// require('./backbone/models/room-availability-saver.js'); is duplicative.
// require('./backbone/models/room-type.js'); is duplicative.
// require('./backbone/models/sales-report.js'); is duplicative.
// require('./backbone/models/spa-allocation.js'); is duplicative.
// require('./backbone/models/spa-availability-saver.js'); is duplicative.
// require('./backbone/models/stats-interactions.js'); is duplicative.
// require('./backbone/models/supplier.js'); is duplicative.
// require('./backbone/models/time-block.js'); is duplicative.
// require('./backbone/models/unpaid-remittance.js'); is duplicative.
// require('./backbone/models/venue.js'); is duplicative.
// require('./backbone/models/VenueStatistics.js'); is duplicative.
// require('./backbone/models/WorkingHours.js'); is duplicative.
require('./backbone/routers/calendar-router.js');
require('./backbone/routers/clients-router.js');
require('./backbone/routers/dashboard-router.js');
require('./backbone/routers/header-router.js');
require('./backbone/routers/marketing-router.js');
require('./backbone/routers/menu-router.js');
require('./backbone/routers/profile-router.js');
require('./backbone/routers/reports-router.js');
require('./backbone/routers/settings-router.js');
require('./backbone/routers/store-router.js');
require('./backbone/routers/team-router.js');
// false is duplicative.
require('./backbone/views/calendar-view.js');
require('./backbone/views/clients-view.js');
require('./backbone/views/dashboard-view.js');
require('./backbone/views/dialog.js');
require('./backbone/views/form-view.js');
require('./backbone/views/header-view.js');
require('./backbone/views/identity-verification-view.js');
require('./backbone/views/marketing-view.js');
require('./backbone/views/menu-view.js');
require('./backbone/views/mobile-app-banner.js');
require('./backbone/views/onboarding-wizard.js');
require('./backbone/views/profile-view.js');
require('./backbone/views/reports-view.js');
require('./backbone/views/settings-view.js');
require('./backbone/views/store-view.js');
require('./backbone/views/team-view.js');
require('./backbone/views/calendar/appointment-alerts-view.js');
require('./backbone/views/calendar/appointment-calendar-view.js');
require('./backbone/views/calendar/appointment-tooltip-view.js');
require('./backbone/views/calendar/block-tooltip-view.js');
require('./backbone/views/calendar/calendar-type-select-view.js');
require('./backbone/views/calendar/dated-alerts-view.js');
require('./backbone/views/calendar/datepicker-view.js');
require('./backbone/views/calendar/datetable-calendar-view.js');
require('./backbone/views/calendar/floating-event-mode-header-view.js');
require('./backbone/views/calendar/header-pane-base.js');
require('./backbone/views/calendar/hide-non-working-toggle.js');
require('./backbone/views/calendar/keyboard-shortcuts.js');
require('./backbone/views/calendar/month-picker.js');
require('./backbone/views/calendar/no-permission-dialog.js');
require('./backbone/views/calendar/voucher-redemption-view.js');
require('./backbone/views/calendar/appointment/header-pane-view.js');
require('./backbone/views/calendar/dated/header-pane-view.js');
require('./backbone/views/calendar/dated/tooltip.js');
require('./backbone/views/dashboard/interactions.js');
require('./backbone/views/dialogs/alert.js');
require('./backbone/views/dialogs/booking.js');
require('./backbone/views/dialogs/client-deletion-confirm.js');
require('./backbone/views/dialogs/consumer.js');
require('./backbone/views/dialogs/iglu-model.js');
require('./backbone/views/dialogs/image-upload.js');
require('./backbone/views/dialogs/images-gallery-upload-crop.js');
require('./backbone/views/dialogs/lightweight-mode.js');
require('./backbone/views/dialogs/login-request.js');
require('./backbone/views/dialogs/menu-enclosed-offer-change-notification.js');
require('./backbone/views/dialogs/menu-offer-enclosed-sku-change-prevention.js');
require('./backbone/views/dialogs/menu-package-included-offer-change-notification.js');
require('./backbone/views/dialogs/new-employee-image.js');
require('./backbone/views/dialogs/order2.js');
require('./backbone/views/dialogs/validation-error.js');
require('./backbone/views/dialogs/alert/ajax-retry.js');
require('./backbone/views/dialogs/booking/appointment.js');
require('./backbone/views/dialogs/booking/dated.js');
require('./backbone/views/dialogs/booking/evoucher.js');
require('./backbone/views/dialogs/booking/rejection.js');
require('./backbone/views/dialogs/calendar/close-out.js');
require('./backbone/views/forms/appointment2.js');
require('./backbone/views/forms/block-time.js');
require('./backbone/views/forms/calendar.js');
require('./backbone/views/forms/customer-import.js');
require('./backbone/views/forms/customer.js');
require('./backbone/views/forms/employee-category.js');
require('./backbone/views/forms/employee.js');
require('./backbone/views/forms/menu-group.js');
require('./backbone/views/forms/menu-offer2.js');
require('./backbone/views/forms/menu-upgrade.js');
require('./backbone/views/forms/multiple-menu-services.js');
require('./backbone/views/forms/multiple-menu-treatments.js');
require('./backbone/views/forms/opening-times.js');
require('./backbone/views/forms/pos-transaction-complete.js');
require('./backbone/views/forms/profile.js');
require('./backbone/views/forms/settings.js');
require('./backbone/views/forms/appointment2/item.js');
require('./backbone/views/forms/appointment2/multi-services-item.js');
require('./backbone/views/forms/appointment2/time-gap.js');
require('./backbone/views/forms/appointment2/item/data.js');
require('./backbone/views/forms/calendar/room-availability.js');
require('./backbone/views/forms/calendar/room-day-details.js');
require('./backbone/views/forms/calendar/spa-availability.js');
require('./backbone/views/forms/calendar/spa-day-details.js');
require('./backbone/views/forms/customer/mass-email-no-filters-warning.js');
require('./backbone/views/forms/customer/mass-email-target-audience-confirmation.js');
require('./backbone/views/forms/customer/mass-email.js');
require('./backbone/views/forms/employee/edit-image.js');
require('./backbone/views/forms/employee/link-external-calendar.js');
require('./backbone/views/forms/employee/unlink-external-dialog.js');
require('./backbone/views/forms/menu-offer2/confirm-copy-dialog.js');
require('./backbone/views/forms/menu-offer2/rich-editor.js');
require('./backbone/views/forms/menu-offer2/saving.js');
require('./backbone/views/forms/menu-offer2/treatment-pricing-and-employees.js');
require('./backbone/views/forms/menu-offer2/description/treatments-list-select.js');
require('./backbone/views/forms/menu-offer2/pricing/dated.js');
require('./backbone/views/forms/menu-offer2/pricing/package-pricing.js');
require('./backbone/views/forms/menu-offer2/pricing/package-services.js');
require('./backbone/views/forms/menu-offer2/pricing/service-details.js');
require('./backbone/views/forms/menu-offer2/pricing/treatment-type-select.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/dates-edit-dialog.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/dates.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/edit-dialog.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/edit.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/inline-edit.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/list.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/edit/rooms.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/edit/simple.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/list/rooms.js');
require('./backbone/views/forms/menu-offer2/pricing/dated/list/simple.js');
require('./backbone/views/forms/menu-offer2/pricing/package-services/category-mismatch-alert.js');
require('./backbone/views/forms/menu-offer2/pricing/package-services/item.js');
require('./backbone/views/forms/menu-offer2/pricing/package-services/select-services.js');
require('./backbone/views/forms/menu-offer2/pricing/package-services/select-services/message.js');
require('./backbone/views/forms/menu-offer2/tabs/description-tab.js');
require('./backbone/views/forms/menu-offer2/tabs/distribution-tab.js');
require('./backbone/views/forms/menu-offer2/tabs/fine-print-tab.js');
require('./backbone/views/forms/menu-offer2/tabs/pricing-tab.js');
require('./backbone/views/forms/menu-offer2/tabs/tab-base.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-base.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-currency-input.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-error.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-line-item.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-lite.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-payment-button.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-payment.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-price-calculator.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-product.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-quantity-selector.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-running-balance.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-service-edit.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-services.js');
require('./backbone/views/forms/pos-checkout/pos-checkout-summary.js');
require('./backbone/views/forms/pos-checkout/pos-checkout.js');
require('./backbone/views/header/icons.js');
require('./backbone/views/header/notifications.js');
require('./backbone/views/header/search.js');
require('./backbone/views/menu/featured-offer-view.js');
require('./backbone/views/menu/menu-filters-view.js');
require('./backbone/views/menu/offer-group-list-view.js');
require('./backbone/views/menu/offer-group-view.js');
require('./backbone/views/menu/offer-view.js');
require('./backbone/views/menu/services-tab.js');
require('./backbone/views/onboarding-wizard/terms-and-conditions.js');
require('./backbone/views/onboarding-wizard/tracking.js');
require('./backbone/views/partials/consumer-block.js');
require('./backbone/views/partials/form-checkbox-list.js');
require('./backbone/views/partials/opening-hours.js');
require('./backbone/views/partials/popup-menu.js');
require('./backbone/views/partials/toggleable-list.js');
require('./backbone/views/partials/weekday-checkboxes.js');
require('./backbone/views/reports/sales-view.js');
require('./backbone/views/reports/table-view.js');
require('./backbone/views/settings/employee-categories-view.js');
require('./backbone/views/settings/employee-employees-master-view.js');
require('./backbone/views/settings/notifications-client-view.js');
require('./backbone/views/settings/notifications-settings-view.js');
require('./backbone/views/settings/online-booking-integrations-view.js');
require('./backbone/views/settings/online-booking-settings-view.js');
require('./backbone/views/settings/online-booking-widget-view.js');
require('./backbone/views/settings/opening-hours-view.js');
require('./backbone/views/settings/supplier-bank-details-view.js');
require('./backbone/views/settings/supplier-payments-view.js');
require('./backbone/views/settings/supplier-settings-view.js');
require('./backbone/views/settings/venue-details-view.js');
require('./backbone/views/settings/venue-pos-settings-view.js');
require('./backbone/views/settings/venue-resources-view.js');
require('./backbone/views/settings/venue-vouchers.js');
require('./backbone/views/settings/dynamic/employee-employees-view.js');
require('./backbone/views/settings/venue-details/map.js');
require('./wahanda/analytics.js');
// false is duplicative.
require('./wahanda/appointment.js');
require('./wahanda/base.js');
require('./wahanda/comparators.js');
require('./wahanda/currency.js');
require('./wahanda/customer-contact-showing-check.js');
require('./wahanda/date.js');
require('./wahanda/debug-bar.js');
require('./wahanda/device.js');
require('./wahanda/dialogs.js');
require('./wahanda/discount-calculator.js');
require('./wahanda/error-report.js');
require('./wahanda/es6bridge.js');
require('./wahanda/event.js');
require('./wahanda/features.js');
require('./wahanda/form.js');
require('./wahanda/initial-generator.js');
require('./wahanda/link.js');
// false is duplicative.
require('./wahanda/localStorage.js');
require('./wahanda/media-query.js');
require('./wahanda/number.js');
require('./wahanda/page.js');
require('./wahanda/permissions.js');
require('./wahanda/platform.js');
require('./wahanda/screen-density.js');
require('./wahanda/shortcuts.js');
require('./wahanda/template.js');
require('./wahanda/text.js');
require('./wahanda/time-profiler.js');
require('./wahanda/time.js');
require('./wahanda/toastNotifications.js');
require('./wahanda/url.js');
require('./wahanda/util.js');
require('./wahanda/validate.js');
require('./wahanda/wizzard.js');

// Include the React code
require('../javascripts-es6/es6-exports');
