/* global _ BackboneEx */
/* eslint indent:0 */
/* eslint func-names: 0 */
/* eslint prefer-spread: 0 */

import inlineClient from 'components/calendar/InlineClient/initializer';
import customerDuplicateCheck from 'utilities/customer-duplicate-check';
import { post } from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import moment from 'common/moment';
import { trackEvent } from 'common/analytics';
import { AppointmentAnalytics } from 'components/calendar/CalendarEventEditor/tracking';
import { AppointmentRepeatAnalytics } from 'components/calendar/CalendarEventEditor/AppointmentRepeatRow/utils/tracking';

(() => {
  const BaseDialog = BackboneEx.View.Base;

  const FLOW_TYPE_NEW = 'new';
  const FLOW_TYPE_UPDATE = 'update';

  function isOrderView(view) {
    return view instanceof App.Views.Forms.Appointment2.MultiServicesItem;
  }

  function modelUpToDate(appt) {
    const test = appt.id > 0 && appt.get('venueCustomerId') > 0 && appt.get('appointmentDate');

    return !!test;
  }

  // The Unified Day View dialog
  const AppointmentView = BaseDialog.extend(
    {
      events: {
        'click .js-transaction-item': 'openTransaction',
        'click .js-fee-commissions-link': 'openCommissionsLinkClick',
        'change .js-date': 'onDateChange',
      },

      templateId: 'appointment2-form-template',
      errorTemplateId: 'appointment2-form-error-template',
      dialogTitle: Wahanda.lang.calendar.appointmentForm.title,
      // Is the appointment list loaded and ready to be rendered?
      collectionReady: false,
      renderPending: false,
      singleAppointmentMode: false,
      initialCustomerId: null,
      fetchModel: false,
      preventRender: false,
      fetchHiddenAppointments: false,
      // The type of the appointment flow: new, update
      flowType: '',

      // Instances of collections
      menu: null,
      employees: null,
      menuTreatments: null,
      posStatus: null,

      buttons: {},

      views: null,
      gapViews: null,
      customerModel: null,
      mediator: null,
      changedForms: null,
      alreadyHighlited: false,
      isSaving: false,

      repeatModel: undefined,

      /**
       * @param Object options
       * > Boolean singleAppointmentMode (optional) Should only the passed in appointment be rendered, not all day?
       * > Boolean fetchModel (optional) Setting to false does not fetch the Appointment model, renders as-is.
       */
      initialize(options) {
        BaseDialog.prototype.initialize.apply(this, arguments); // eslint-disable-line prefer-rest-params

        this.mediator = Wahanda.Event.createMediator({
          CUSTOMER_FOUND: 'cust-found',
          CUSTOMER_SET_WALKIN: 'cust-set-walkin',
          CUSTOMER_UPDATE: 'cust-updated',
          SUBSEQUENT_RESCHEDULE_REQUIRED: 'subsequent-reschedule-required',
          POSSIBLE_RESCHEDULE: 'possible-reschedule',
          UNSAVED_APPOINTMENTS_CHANGED: 'unsaved-appt-views-changed',
          APPOINTMENT_SAVED: 'appt:saved',
          STANDALONE_APPOINTMENT_CONFIRMED: 'single-appt:confirmed',
          APPOINTMENT_GROUP_CONFIRMED: 'appt-group:confirmed',
          APPOINTMENT_GROUP_CONFIRM_ERROR: 'appt-group:confirm-error',
          APPOINTMENT_GROUP_REJECTED: 'appt-group:rejected',
          APPOINTMENT_GROUP_SET_NOSHOW: 'appt-group:noshow',
          APPOINTMENT_GROUP_CANCELLED: 'appt-group:cancelled',
          APPOINTMENT_GROUP_SAVED: 'appt-group:saved',
          DIALOG_POSITION_REFRESH_NEEDED: 'refresh-position',
          REQUEST_CHANGE_TO_PACKAGE: 'package-change-request',
          FORM_VALUES_CHANGE: 'appt:form-change',
          VIEW_REFRESHED: 'appt:refreshed',
          CLOSE_ACTION_REQUIRED: 'views:close',
          START_RESCHEDULE: 'reschedule:start',
        });

        this.views = [];
        this.gapViews = [];

        this.collection = new App.Collections.CustomerAppointments();
        this.collection.utmSource = 'UDV';
        this.customerModel = new App.Models.Customer(options.customerData);

        // Bind Functions to the view for always correct contexts
        _.bindAll(this, 'ready');

        const opt = options || {};
        this.singleAppointmentMode = !!opt.singleAppointmentMode;
        this.preventRender = !!opt.preventRender;

        if (opt.initialCustomerId) {
          this.initialCustomerId = opt.initialCustomerId;
        }

        this.listenTo(this.collection, 'destroy', this.onRemoveUnsavedView);
        // Bind to mediator events
        this.listenTo(this.mediator, this.mediator.CUSTOMER_FOUND, this.onCustomerChosen);
        this.listenTo(this.mediator, this.mediator.CUSTOMER_SET_WALKIN, this.onCustomerSetWalkIn);
        this.listenTo(this.mediator, this.mediator.CUSTOMER_UPDATE, this.setTitle);
        this.listenTo(
          this.mediator,
          this.mediator.SUBSEQUENT_RESCHEDULE_REQUIRED,
          this.subsequentReschedule,
        );
        this.listenTo(this.mediator, this.mediator.POSSIBLE_RESCHEDULE, this.onReschedule);
        this.listenTo(this.mediator, this.mediator.APPOINTMENT_CHECKED_OUT, this.close);
        this.listenTo(this.mediator, this.mediator.CLOSE_ACTION_REQUIRED, this.close);
        this.listenTo(
          this.mediator,
          this.mediator.APPOINTMENT_GROUP_CONFIRMED,
          this.onGroupConfirm,
        );
        this.listenTo(this.mediator, this.mediator.APPOINTMENT_GROUP_CONFIRM_ERROR, this.close);
        this.listenTo(
          this.mediator,
          this.mediator.APPOINTMENT_GROUP_REJECTED,
          this.onGroupRejected,
        );
        this.listenTo(
          this.mediator,
          this.mediator.APPOINTMENT_GROUP_SET_NOSHOW,
          this.onGroupNoShow,
        );
        this.listenTo(
          this.mediator,
          this.mediator.APPOINTMENT_GROUP_CANCELLED,
          this.onGroupCancelled,
        );
        this.listenTo(this.mediator, this.mediator.APPOINTMENT_GROUP_SAVED, this.onGroupSaved);
        this.listenTo(
          this.mediator,
          this.mediator.DIALOG_POSITION_REFRESH_NEEDED,
          this.refreshPosition,
        );
        this.listenTo(
          this.mediator,
          this.mediator.REQUEST_CHANGE_TO_PACKAGE,
          this.onNewPackageCreated,
        );
        this.listenTo(
          this.mediator,
          this.mediator.FORM_VALUES_CHANGE,
          this.onAppointmentFormChange,
        );
        this.listenTo(this.mediator, this.mediator.VIEW_REFRESHED, this.updateFooterButtons);
        this.listenTo(this.mediator, this.mediator.START_RESCHEDULE, this.startRescheduleMode);

        this.listenTo(App, Wahanda.Event.TRANSACTION_CANCELLED, this.markRefunded);

        this.changedForms = [];

        if (!Wahanda.Device.isMacOs()) {
          this.options.dialogClass += ' with-styled-inputs';
        }

        if (this.options.singleAppointmentMode) {
          this.fetchSingleAppointmentMode();
        }
        if (this.options.orderSetupData) {
          this.setupDataFromOrder(this.options.orderSetupData);
        } else if (this.options.orderSetupOrderId) {
          this.fetchOrderDataAndRender(this.options.orderSetupOrderId);
        }
      },

      prepareModels() {
        const self = this;
        const promises = [];
        let allowReady = true;
        let model;

        function enableHiddenAppointmentFetching() {
          const aProto = App.Models.Appointment.prototype;
          self.collection.customAppointmentStatuses.push(
            aProto.STATUS_CANCELLED,
            aProto.STATUS_REJECTED,
          );
        }

        function addCustomerUpdateToPromises(customerId) {
          self.customerModel.set('id', customerId);
          promises.push(self.customerModel.fetch());
        }

        // Setup the view from a fully fetched model.
        function setupFromModel(data) {
          // We have the date and Customer ID.
          // Fetch the collection based on that.
          self.options.customerId = data.get('venueCustomerId');
          self.options.venueCustomerId = data.get('venueCustomerId');
          self.options.date = data.get('appointmentDate');

          self.options.highlightApptId = data.get('id');
          self.customerModel = data.getCustomer();

          self.collection.add(data);

          // Add additional statuses for by default not visible appointments
          if (data.isCancelled() || data.isRejected()) {
            enableHiddenAppointmentFetching();
          }
        }

        this.flowType = FLOW_TYPE_UPDATE;
        if (this.model && (modelUpToDate(this.model) || this.fetchModel)) {
          setupFromModel(this.options.model);
          this.model = null;
        }

        if (this.model && this.model.id > 0) {
          model = this.model;
          // We do everything through the collection
          this.model = null;

          const populate = function () {
            setupFromModel(model);
            self.prepareModels();
          };

          model.fetch().done(populate).fail(self.handleFatalApiError.bind(self));

          // Don't allow calling ready just yet.
          // We will call self again with more known data about the appt and customer.
          allowReady = false;
        } else if (this.options.date) {
          this.collection.customerId = this.options.customerId;
          this.collection.date = this.options.date;
          // Note: the customer model might be already set during the Appointment model fetch
          if (
            this.options.customerId > 0 &&
            (this.customerModel.id !== this.options.customerId || this.customerModel.get('name'))
          ) {
            addCustomerUpdateToPromises(this.options.customerId);
          }
          if (this.fetchHiddenAppointments) {
            enableHiddenAppointmentFetching();
          }

          if (
            this.singleAppointmentMode &&
            this.collection.at(0) &&
            this.collection.at(0).belongsToGroup()
          ) {
            const apptGroup = new App.Collections.AppointmentGroup(null, {
              id: this.collection.at(0).getGroupId(),
            });
            const agPromise = apptGroup.fetch();
            promises.push(agPromise);
            agPromise.done(function () {
              self.addGroupToCollection(apptGroup);
            });
          } else if (!this.singleAppointmentMode) {
            promises.push(this.collection.fetch());
          }
        } else {
          this.flowType = FLOW_TYPE_NEW;

          if (this.model) {
            model = this.model;
            this.model = null;
          } else {
            model = new App.Models.Appointment();
          }
          // A new appointment group.
          this.collection.add(model);

          if (this.initialCustomerId) {
            addCustomerUpdateToPromises(this.initialCustomerId);
            model.set('venueCustomerId', this.initialCustomerId);
          }
        }

        // Start fetching the collections now, so we don't have to wait for API later.
        const otherModels = this.fetchOtherModels();
        if (allowReady) {
          // Load all collections and proceed to rendering
          promises.push(otherModels);

          // Wait for all promises to complete
          $.when
            .apply($, promises)
            .done(function () {
              // Render/update the appointments after fetching
              self.ready();

              // Also fetch Checkouts, render later
              if (self.flowType !== FLOW_TYPE_NEW) {
                self
                  .fetchCheckouts()
                  .done(() => {
                    self.renderCheckout.call(self);
                    _.invoke(self.views, 'recalculateAvailability');
                  })
                  .fail(self.handleFatalApiError.bind(self));
              }
            })
            .fail(function () {
              self.handleFatalApiError();
            });
        }

        if (this.options.initialCollectionStructure) {
          const initialList = this.options.initialCollectionStructure;
          this.options.initialCollectionStructure = null;
          // Render the Appointments with initial structure.
          // But we must wait for other models to be fetched before we can render. But as those
          // should be cached, the UI impact is minimal.
          $.when(otherModels).done(
            function () {
              this.collection.reset(initialList);
              this.renderInitialList();
            }.bind(this),
          );

          this.renderCustomerFromInitialModel();
        }
      },

      fetchCheckouts() {
        const self = this;

        const checkoutCollection = new App.Collections.Checkouts();
        checkoutCollection.setFilters({
          venueCustomerId: self.options.venueCustomerId,
          date: self.collection.date,
        });

        const drd = $.Deferred();
        checkoutCollection
          .fetch({
            success(initialCheckoutData) {
              const initalCheckout = initialCheckoutData;

              self.checkouts = initalCheckout;
              const missingCheckouts = self.collection.missingTransactions(
                initialCheckoutData.models,
              );
              if (!missingCheckouts || missingCheckouts.length === 0) {
                drd.resolve();
              } else {
                const checkoutCollectionMissing = new App.Collections.Checkouts();
                checkoutCollectionMissing.setFilters({
                  checkoutIds: missingCheckouts,
                });

                checkoutCollectionMissing.fetch({
                  success(data) {
                    self.checkouts.add(data.models);
                    drd.resolve();
                  },
                });
              }
            },
          })
          .fail(drd.reject);

        return drd.promise();
      },

      /**
       * Fetches non-directly appointment related models.
       *
       * @returns Promise
       */
      fetchOtherModels() {
        const menuLoaded = Wahanda.Cache.menu();
        const employeesLoaded = Wahanda.Cache.employees();
        const employeeCategoriesLoaded = Wahanda.Cache.employeeCategories();
        const treatmentsLoaded = Wahanda.Cache.menuTreatments();
        const posStatusLoaded = App.config.get('venue').pointOfSaleEnabled
          ? Wahanda.Cache.posStatus()
          : null;

        const self = this;
        return $.when(
          menuLoaded,
          employeesLoaded,
          treatmentsLoaded,
          posStatusLoaded,
          employeeCategoriesLoaded,
        ).done(function (menu, employees, treatments, posStatus, employeeCategories) {
          self.menu = menu;
          self.employees = employees;
          self.employeeCategories = employeeCategories;
          self.menuTreatments = treatments;
          self.posStatus = posStatus;
        });
      },

      fetchSingleAppointmentMode() {
        // I think this function will not work and is deprecated.

        // Prevent rendering of appointments until all is fetched.
        this.preventRender = true;
        // Don't fetch other appointments for the day, show only the single one.
        this.singleAppointmentMode = true;
        // Fetch the model, async.
        this.fetchAppointmentLightweight(
          // App.mainViewOptions.initialDialog,
          function onResult(data) {
            // Re-fetch the model only if the appt was confirmed
            this.fetchModel = data.confirmed;
            this.preventRender = false;
            this.render();

            if (!App.isRestrictedMode()) {
              // When model is fetched, move calendar to the date it is in
              this.options.changeCalendarDate(
                Wahanda.Date.createDate(appointment.get('appointmentDate')), // eslint-disable-line no-undef
              );
            }
          }.bind(this),
        );
      },

      /**
       * Fetch the appointment. If that fails, try doing a lightweight authentication and fetching again.
       * If needed, this will confirm the appointment too.
       *
       * @param App.Views.Dialogs.Appointment2 apptView
       * @param Object urlDialogOptions
       * @param Function callback
       */
      fetchAppointmentLightweight(callback) {
        const urlDialogOptions = this.options.lightweightFetchOptions;
        const whenModelFetched = function () {
          if (urlDialogOptions && urlDialogOptions.action === 'confirm') {
            // We have to confirm this appointment right away, before opening it.
            this.model.confirm(
              function () {
                callback({ confirmed: true });
              },
              function () {
                this.showError(Wahanda.lang.calendar.appointments.errors.couldNotConfirm);
              }.bind(this),
              true,
            );
          } else {
            callback({ confirmed: false });
          }
        }.bind(this);

        // This will try a light-weight authenticated model fetch.
        const tryLightweightFetch = BackboneEx.Tool.ModelLightweightFetch({
          onSuccess: whenModelFetched,
          onError: App.Views.Calendar.NoPermission.open,
        });

        this.model
          .fetch({
            error: tryLightweightFetch,
            skipErrorHandling: true,
          })
          .done(whenModelFetched);
      },

      /**
       * Is called when all data is ready for rendering.
       */
      ready() {
        this.collectionReady = true;
        if (this.renderPending) {
          if (this.$el.parents('body').length === 0) {
            // The dialog is closed. Stop here.
            return;
          }
          const model = this.collection.at(0);
          this.render({
            // Don't focus anything if
            disableFocusing: model && model.id > 0,
            fromReady: true,
          });
        }
      },

      canShowCustomerContacts() {
        // We cache the value on first check as during date/time updates the
        // collection might return different results. Yeah, data mutability.
        return this.canShowCustomerContactsCached == null
          ? this.collection.canShowCustomerContacts()
          : this.canShowCustomerContactsCached;
      },

      setupDataFromOrder(order) {
        this.collection.loadFromOrder(order);

        this.customerModel.set('id', order.getCustomerId());

        this.preventRender = true;
        $.when(this.fetchOtherModels(), this.customerModel.fetch()).done(() => {
          this.collectionReady = true;
          this.preventRender = false;
          this.render();
        });
      },

      fetchOrderDataAndRender(orderId) {
        this.preventRender = true;
        this.render();

        const order = new App.Models.Order({ id: orderId });

        order.fetch().done(
          function () {
            this.setupDataFromOrder(order);
          }.bind(this),
        );
      },

      notifyRendered() {
        this.setMaxScrollHeight();
        this.trigger('rendered');
      },

      maybeHightlightInitialAppointment() {
        const toHighlight = this.options.highlightApptId;

        if (this.collection.length <= 1 || !toHighlight || this.alreadyHighlited) {
          return;
        }

        this.scrollToAppt(
          toHighlight,
          function () {
            this.highlightAppt([toHighlight]);
          }.bind(this),
        );
        this.alreadyHighlited = true;
      },

      render(options) {
        if (!this.rendered) {
          this.$el.html(Wahanda.Template.get(this.templateId));
          this.rendered = true;
        }

        if (this.model) {
          if (this.model.isCheckedOut()) {
            const transactions = Wahanda.Template.renderTemplate(
              'appointment2-checkout-transactions',
              { transactions: [{}] },
            );
            this.$('.js-checkout-transactions').html(transactions);
          } else if (this.model.isUnconfirmed() || this.model.isConfirmed()) {
            this.updateFooterButtons();
          }
        } else if (!App.config.get('venue').pointOfSaleEnabled) {
          if (
            this.collection &&
            !this.collection.allCheckedOut() &&
            this.collection.canBeCheckedOut()
          ) {
            this.hideTransactionHistory();
          }
        }

        const customOptions = options || {};

        if (this.preventRender || !this.collectionReady) {
          this.$('.full-loading').loadmask();
        }
        // Prevent the rendering.
        // Outer logic is handling something, e.g. appointment confirmation before rendering.
        if (this.preventRender) {
          return;
        }
        if (!this.collectionReady) {
          this.renderPending = true;
          this.prepareModels();
          return;
        }

        // Sometimes the AppointmentGroups contain links to more appointments than the backend
        // returns. This is due to bad data in the DB, and it should not be the case, but is for now.
        // This method checks it and, if pointers to non-returned appointments exist, errors out.
        if (!this.checkIntegrity()) {
          alert(
            `${Wahanda.lang.calendar.appointments.errors.apptGroupDataError} (ERR_UDV_INTEGRITY)`,
          );
          this.close();
          return;
        }

        this.renderPending = false;
        this.updateFooterButtons();

        if (
          !customOptions.fromReady ||
          this.views.length === 0 ||
          this.collection.lastSyncHadChanges
        ) {
          this.removeViewsFromDOM();
          this.renderContent();
        }

        this.unloadmask();

        // scroll and highlight selected appointment
        if (this.collection.length > 1 && this.options.highlightApptId) {
          this.maybeHightlightInitialAppointment();
        } else if (!customOptions.disableFocusing) {
          // Highlight the first Appointment's element
          // It's done in a setTimeout() as React16 seems to render one tick later,
          // thus this code missing the SelectDropdown node.
          setTimeout(() => {
            this.$('.appointment--item')
              .find(':tabbable')
              .not(':disabled')
              .not('textarea')
              .first()
              .focus();
          }, 0);
        }

        this.options.highlightApptId = null;

        if (this.flowType !== FLOW_TYPE_NEW) {
          this.setTitle();
        }

        // Reset the possible passed in rebooking data as we need it only for initial render.
        this.options.initialDuration = null;

        this.notifyRendered();
        if (this.canShowCustomerContactsCached == null) {
          this.canShowCustomerContactsCached = this.collection.canShowCustomerContacts();
        }
      },

      checkIntegrity() {
        return this.collection.checkIntegrity();
      },

      renderContent() {
        this.renderAppointments();
        this.renderGaps();
        this.renderCustomer();
        this.renderCustomerFormLinks();
      },

      hideTransactionHistory() {
        this.$('.js-checkout-transactions').html('');
        this.$('.js-checkout-products').html('');
      },

      updateFooterButtons() {
        // Save: if new model
        // Checkout: if existing models, no unconfirmed ones and no changes
        const buttons = {};
        const missingSkuAttentionElement =
          this.$('.js-missing-sku-attention').css('display') === 'block';
        let saveButtonClasses = 'dialog2--button-right dialog2--button-green';

        if (missingSkuAttentionElement) {
          saveButtonClasses += ' dialog2--button-disabled';
        }

        const allNew =
          this.collection.length > 0 &&
          this.collection.all(function (appt) {
            return !appt.id;
          });

        const hasSavedAppointments = function () {
          // Check initial structure, because we want to show the buttons as soon as possible
          const initStruct = this.options.initialCollectionStructure;
          if (initStruct) {
            return initStruct.appointments.length > 0 || initStruct.appointments.length > 0;
          }

          return !allNew;
        }.bind(this);

        const allViewsValid = function () {
          return _.all(this.views, function (view) {
            return view.isValidReturnOnly();
          });
        }.bind(this);

        const changesExist = this.changedForms.length > 0;
        const saveButton = {
          title: Wahanda.lang.shared.save,
          classes: saveButtonClasses,
        };
        const checkoutButton = {
          title: Wahanda.lang.calendar.appointments.multi.buttons.checkout,
          classes: 'dialog2--button-right dialog2--button-blue checkout-button',
          onRight: true,
        };
        if (allNew) {
          // Only unsaved appointments in sight, or some unsaved
          buttons.save = saveButton;
        } else if (
          changesExist ||
          this.collection.hasUnconfirmed() ||
          this.collection.hasUnsaved()
        ) {
          buttons.save = saveButton;

          if (this.collection.hasUnconfirmed()) {
            // Override the title
            buttons.save.title =
              changesExist || this.collection.hasUnsaved()
                ? Wahanda.lang.calendar.appointments.multi.buttons.saveConfirm
                : Wahanda.lang.calendar.appointments.multi.buttons.confirm;
          }
        } else if (
          !App.isRestrictedMode() &&
          !this.collection.allCheckedOut() &&
          this.collection.canBeCheckedOut()
        ) {
          // All appointments eigible for Checkout
          buttons.checkout = checkoutButton;
        } else {
          // No actions available, no buttons in the footer.
        }

        const canEditSomeCalendar =
          (Wahanda.Permissions.editAnyCalendar() || Wahanda.Permissions.editOwnCalendar()) &&
          !App.isRestrictedMode();

        buttons.addNext =
          !canEditSomeCalendar || !allViewsValid()
            ? null
            : {
                title: Wahanda.lang.calendar.appointments.multi.buttons.addNewAppointment,
                classes: 'appointment--button button button-grey',
                onClick: this.onAddClick.bind(this),
              };

        const canRebook =
          canEditSomeCalendar && hasSavedAppointments() && this.canShowCustomerContacts();
        buttons.rebook = !canRebook
          ? null
          : {
              title: Wahanda.lang.calendar.appointments.multi.buttons.rebook,
              classes: 'appointment--button button button-grey',
              onClick: this.startRebookingMode.bind(this),
            };

        const canRepeat =
          canEditSomeCalendar && !hasSavedAppointments() && !this.$('.js-repeat-row').html();
        buttons.repeat =
          !canRepeat || !allViewsValid()
            ? null
            : {
                title: Wahanda.lang.calendar.appointments.multi.buttons.repeat,
                classes: 'appointment--button button button-grey js-repeat',
                onClick: this.renderRepeatAppointmentRow.bind(this),
              };

        // Previous and next buttons might be equal. Don't do expensive DOM operations.
        if (!_.isEqual(this.buttons, buttons)) {
          this.buttons = buttons;
        }
        this.options.updateButtons(buttons);
        this.notifyRendered();
        const top = ($(window).height() - this.$el.height()) / 2;
        this.options.topChange(top);
      },

      renderAppointments() {
        const self = this;
        let hasEditableAppointments = false;
        const $container = this.$('.js-appointments').empty();
        let initialDuration = this.options.initialDuration;
        let redeemEvoucher = this.options.redeemEvoucher;
        // Render per-appointment views
        const collection = this.collection;
        // the collection is composed by all the orders of the day for the
        // same user, ordered by crescent time. So the first item is also
        // the first appointment of the day for the user
        let isFirstForToday = true;
        collection.each(function (model) {
          const customOptions = {
            isFirstForToday,
          };
          if (self.flowType === FLOW_TYPE_NEW && initialDuration) {
            customOptions.initialDuration = initialDuration;
            initialDuration = null;
          }
          if (redeemEvoucher) {
            customOptions.redeemEvoucher = true;
            customOptions.evoucherReference = self.options.evoucherReference;
            redeemEvoucher = false;
          }

          self.addAppointmentView(model, $container, customOptions);

          hasEditableAppointments = hasEditableAppointments || !model.isReadOnly();
          initialDuration = null;
          isFirstForToday = false;
        });

        this.hasEditableAppointments = hasEditableAppointments;

        const last = this.views[this.views.length - 1];
        _.each(this.views, function (view) {
          view.isLastView = view === last; // eslint-disable-line no-param-reassign
        });
      },

      addAppointmentView(model, $container, customOptions, doNotAddToDOM) {
        const self = this;

        function getPackageView(packageGroupProp) {
          let match = null;

          function viewMatches(view) {
            return view.collection === packageGroupProp;
          }

          _.any(self.views, function (view) {
            if (view.apptViews) {
              // Search the MS View for this Package
              match = _.find(view.apptViews, viewMatches);
            } else if (viewMatches(view)) {
              match = view;
            }
            return !!match;
          });
          return match;
        }

        const packageGroup = this.collection.getAppointmentPackageGroup(model);
        let modelFormated = model;
        if (packageGroup) {
          if (getPackageView(packageGroup)) {
            // We have already the Item view for this package rendered.
            return null;
          }
          // Reset the model to the Package Offer and Sku choice.
          // It's a workaround to support rendering Packages and simple Appts in ItemViews.
          modelFormated = packageGroup.formatAsModel(this.menu.get('offersCollection'));
        }

        const viewOptions = _.extend(
          {
            model: modelFormated,
            collection: packageGroup,
            menu: this.menu,
            employees: this.employees,
            employeeCategories: this.employeeCategories,
            menuTreatments: this.menuTreatments,
            mediator: this.mediator,
            externalSave: this.flowType === FLOW_TYPE_NEW,
            toggleDialogClosing: this.options.toggleDialogClosing,
            showConfirmDeletion: this.options.showConfirmDeletion,
            showConfirmNoShow: this.options.showConfirmNoShow,
            showCantDeleteDialog: this.options.showCantDeleteDialog,
            showInitialDeleteDialog: this.options.showInitialDeleteDialog,
            apptModels: this.collection.models,
          },
          customOptions,
        );
        const view = new AppointmentView.Item(viewOptions);

        // Only render non-group views. Package Appointments are handled by the Package view.
        if (!doNotAddToDOM) {
          this.addView(view, $container);
          if (!modelFormated.id) {
            this.triggerUnsavedAppointmentChangedEvent();
          }
        }

        return view;
      },

      addView(view, $el) {
        let $container = $el;
        if (!$container) {
          $container = this.$('.js-appointments');
        }
        this.views.push(view);
        view.render();
        $container.append(view.$el);
      },

      findGroupView(group) {
        const groupId = group.id;

        function isMultiServicesView(view) {
          return view instanceof AppointmentView.MultiServicesItem;
        }

        const groupView = _.find(
          this.views,
          (view) => !isMultiServicesView(view) && view.collection && view.collection.id === groupId,
        );
        return groupView;
      },

      // Render Appointment list from passed-in appointment data
      renderInitialList() {
        if (!this.checkIntegrity()) {
          // Can't close here - still not initialized, need to wait for render().
          return;
        }

        this.renderContent();
        this.setTitle();
        this.setMaxScrollHeight();
        this.maybeHightlightInitialAppointment();
      },

      triggerUnsavedAppointmentChangedEvent() {
        this.mediator.trigger(this.mediator.UNSAVED_APPOINTMENTS_CHANGED, {
          count: this.collection.getUnsavedAppointmentCount(),
          total: this.collection.length,
        });
      },

      renderGaps() {
        const self = this;
        const viewList = this.views;
        const newGaps = [];

        function findGapView(prevModel, nextModel) {
          return _.find(self.gapViews, function (gapView) {
            return gapView.prevModel === prevModel && gapView.nextModel === nextModel;
          });
        }

        function renderGapView(prevView, apptView) {
          /**
           * Get the model from the View.
           *
           * @param Item or Package view
           * @param Boolean isFirstRequired Is the first, or the last model required? In case of ApptGroup.
           *
           * @returns App.Models.Appointment
           */
          function getModel(view, isFirstRequired) {
            if (view instanceof App.Views.Forms.Appointment2.MultiServicesItem) {
              // Recurse, as Group views can contain other Group views.
              return getModel(view.apptViews[isFirstRequired ? 0 : view.apptViews.length - 1]);
            }
            if (view && view.appointmentViews) {
              return view.appointmentViews[isFirstRequired ? 0 : view.appointmentViews.length - 1]
                .model;
            }
            return view && view.model;
          }

          const prevModel = getModel(prevView, false);
          const nextModel = getModel(apptView, true);
          const comparison = App.Models.Appointment.compareInTime(prevModel, nextModel, {
            keepModelOrder: true,
          });
          const hasTimeGap = comparison && comparison.gap;
          let gapView = findGapView(prevModel, nextModel);

          if (hasTimeGap && comparison.timeDiff > 0) {
            // There is a gap between the two appointments.
            // Render it visually.
            if (!gapView) {
              gapView = new AppointmentView.TimeGap({
                prevModel,
                nextModel,
                mediator: self.mediator,
              });
            }
            gapView.render();
            gapView.$el.insertAfter(prevView.$el);
            newGaps.push(gapView);
          }
        }

        function loopGapRender(services, previousView) {
          let previous = previousView;
          services.forEach((apptView) => {
            if (apptView === previous) {
              return;
            }
            renderGapView(previous, apptView);
            previous = apptView;
          });
          return previous;
        }

        _.each(viewList, function (view, viewPos) {
          let prevView;
          const isMultiServicesView =
            view instanceof App.Views.Forms.Appointment2.MultiServicesItem;
          const isServicePackageView = view.appointmentViews && view.appointmentViews.length > 1;

          if (viewPos === 0) {
            if (isMultiServicesView) {
              prevView = view.apptViews[0];
            } else if (isServicePackageView) {
              prevView = view.appointmentViews[0];
            } else {
              // First view, and not a Group view - no gaps possible.
              return;
            }
          } else {
            prevView = viewList[viewPos - 1];
          }

          if (isMultiServicesView) {
            // In case of PackageAppointment, check all inside appointments for gaps too
            prevView = loopGapRender(view.apptViews, prevView);
          } else if (isServicePackageView) {
            prevView = loopGapRender(view.appointmentViews, prevView);
          } else {
            renderGapView(prevView, view);
          }
        });

        // Remove obsolete gap views
        const removedGaps = _.difference(this.gapViews, newGaps);
        _.invoke(removedGaps, 'remove');

        this.gapViews = newGaps;
      },

      setTitle() {
        const customer = this.customerModel;
        let customerName;
        const firstAppt = this.collection.at(0);
        const canDiscloseFullName = this.canShowCustomerContacts();

        if (!canDiscloseFullName && customer.id) {
          customerName = firstAppt.get('recipientFirstName') || firstAppt.get('consumerFirstName');
        } else if (customer.id && !firstAppt.get('walkIn')) {
          customerName = customer.get('recipientName')
            ? customer.get('recipientName')
            : customer.get('name');
        } else if (firstAppt.get('anonymousNote')) {
          customerName = firstAppt.get('anonymousNote');
        } else {
          customerName = Wahanda.lang.shared.walkin;
        }

        const title = Wahanda.Template.render(
          Wahanda.lang.calendar.appointments.textTemplates.appointmentDialogTitle,
          {
            name: customerName,
            date: Wahanda.Date.formatDate('d M', firstAppt.getStartDate()),
          },
        );
        $('.calendar-edit-title').html(title);
      },

      /**
       * Remove an Appointment View, possibly also recalculating the gap views.
       *
       * @param App.Models.Appointment model
       */
      removeAppointmentView(model) {
        const removedView = this.getViewForModel(model);

        this.changedForms = _.without(this.changedForms, removedView);
        this.views = _.without(this.views, removedView);

        removedView.remove();

        const self = this;
        const recalculateGaps = _.any(this.gapViews, function (gapView) {
          return (
            !self.getViewForModel(gapView.prevModel) || !self.getViewForModel(gapView.nextModel)
          );
        });

        if (recalculateGaps) {
          this.renderGaps();
        }

        this.updateFooterButtons();
      },

      renderCheckout() {
        const checkouts = this.checkouts;
        if (!checkouts || checkouts.length === 0) {
          this.render({
            // Don't focus anything if
            disableFocusing: true,
          });
          return;
        }
        const transactionsList = [];
        let productList = [];
        checkouts.forEach(function (checkout) {
          const transactionQuantityTexts = [];
          if (checkout.get('serviceLineItems').length > 0) {
            const servicesText = Wahanda.Text.pluralizeNumber(
              checkout.get('serviceLineItems').length,
              [
                Wahanda.lang.calendar.appointments.checkouts.serviceSingle,
                Wahanda.lang.calendar.appointments.checkouts.servicesPlural,
              ],
            );
            transactionQuantityTexts.push(servicesText);
          }
          const newProducts = checkout.get('productLineItems') || [];
          productList = productList.concat(newProducts);

          const productCount = _.reduce(
            _.filter(newProducts, { type: 'PRODUCT' }),
            function (running, product) {
              return running + product.quantity;
            },
            0,
          );
          const voucherCount = _.reduce(
            _.filter(newProducts, { type: 'VOUCHER' }),
            function (running, product) {
              return running + product.quantity;
            },
            0,
          );
          if (productCount > 0) {
            const productsText = Wahanda.Text.pluralizeNumber(productCount, [
              Wahanda.lang.calendar.appointments.checkouts.productSingle,
              Wahanda.lang.calendar.appointments.checkouts.productsPlural,
            ]);
            transactionQuantityTexts.push(productsText);
          }

          if (voucherCount > 0) {
            const vouchersText = Wahanda.Text.pluralizeNumber(voucherCount, [
              Wahanda.lang.calendar.appointments.checkouts.voucherSingle,
              Wahanda.lang.calendar.appointments.checkouts.vouchersPlural,
            ]);
            transactionQuantityTexts.push(vouchersText);
          }
          const dateTime = moment(checkout.get('checkoutInformation').created)
            .tz(App.getTimezone())
            .format('YYYY-MM-DD HH:mm:ss');
          const transaction = {
            dateTime,
            amount: Wahanda.Currency.format(checkout.get('checkoutTotals').totalAmount),
            transactionQuantities: transactionQuantityTexts.join(', '),
            checkoutId: checkout.get('checkoutInformation').checkoutId,
            cancelled: checkout.isCancelled(),
          };
          transactionsList.push(transaction);
        });

        productList = _.map(productList, function (product) {
          let quantity = product.quantity;
          if (product.quantity > 1) {
            quantity = `${product.quantity} x ${Wahanda.Currency.format(product.unitPrice)}`;
          }
          return {
            name: product.description,
            amount: Wahanda.Currency.format(product.amount),
            quantity,
          };
        });

        if (productList.length > 0) {
          const products = Wahanda.Template.renderTemplate('appointment2-checkout-products', {
            products: productList,
          });
          this.$('.js-checkout-products').html(products);
        }

        const transactions = Wahanda.Template.renderTemplate('appointment2-checkout-transactions', {
          transactions: transactionsList,
        });
        this.$('.js-checkout-transactions').html(transactions);

        this.setMaxScrollHeight();
        const top = ($(window).height() - this.$el.height()) / 2;
        this.options.topChange(top);
      },

      openTransaction(evt) {
        const checkoutId = $(evt.currentTarget).data('checkout-id');
        let destroy;
        const options = {
          checkoutId,
          date: this.options.date,
          onClose: () => {
            if (destroy) {
              destroy();
            }
          },
        };
        const initializer = App.ES6.Initializers.CheckoutSummaryDialog(
          options,
          Wahanda.Dialogs.getReactContainer(),
        );
        destroy = initializer.destroy;
        initializer.render();
      },

      renderCustomer() {
        const SHADOW_CLASS = 'appointment-head-separator';
        const $dialogTitle = this.$el.closest('.ui-dialog').find('.calendar-edit-title');
        const $node = this.$('.inline-client-create');

        if (this.canShowCustomerContacts()) {
          $node.addClass(SHADOW_CLASS);
          $dialogTitle.removeClass(SHADOW_CLASS);
        } else {
          // Customer contact details should not be shown.
          $node.wHide();
          $dialogTitle.addClass(SHADOW_CLASS);
          // EXIT - don't render the customer.
          return;
        }

        inlineClient.render(
          {
            customerModel: this.customerModel,
            mediator: this.mediator,
            appointmentCollection: this.collection,
            date: this.options.date,
            toggleIgnoreKeyboardEvents: this.options.toggleIgnoreKeyboardEvents,
            hasEditableAppointments: this.hasEditableAppointments,
            renderCallback: this.options.onRender,
          },
          $node[0],
        );
      },

      renderCustomerFormLinks() {
        if (!Wahanda.Features.isEnabled('CD-661-show-consultation-forms-list')) {
          return;
        }
        const $node = this.$('.customer-form-link');

        const customerId = this.options.venueCustomerId;
        const appointmentDate = this.collection.at(0).get('appointmentDate');

        App.ES6.Initializers.CustomerFormLinks(customerId, appointmentDate, $node[0]).render();
      },

      onDateChange() {
        if (this.$('.js-repeat-row').html()) {
          this.renderRepeatAppointmentRow();
        }
      },

      renderRepeatAppointmentRow() {
        const repeatButton = this.$('.js-repeat');
        const repeatRow = this.$('.js-repeat-row');
        const selectedDate =
          this.collection.length > 0 &&
          moment
            .max(this.collection.map((appt) => moment(appt.get('appointmentDate'))))
            .formatApiDateString();

        if (!repeatRow.html()) {
          AppointmentRepeatAnalytics.trackRepeatClick();
        }
        if (!repeatButton && !repeatRow) {
          return;
        }
        repeatButton.remove();
        App.ES6.Initializers.AppointmentRepeatRow({
          node: repeatRow[0],
          selectedDate,
          onRepeatModelChange: (model) => {
            this.repeatModel = model;
          },
          onComponentLoad: (model) => {
            this.repeatModel = model;
          },
          onRemoveClick: () => {
            this.repeatModel = undefined;
            this.updateFooterButtons();
            this.options.heightChange(this.$el.height());
          },
        }).render();

        this.updateFooterButtons();
        this.options.heightChange(this.$el.height());
      },

      renderCustomerFromInitialModel() {
        const model = this.collection.at(0);
        const initCustomer = model ? model.getCustomer() : null;

        if (initCustomer && initCustomer.id > 0) {
          this.customerModel.set(initCustomer.attributes);
          this.renderCustomer();
        }
      },

      /**
       * Reset the collection and add the AppointmentGroup as it's only child.
       *
       * @param App.Collections.AppointmentGroup apptGroup
       */
      addGroupToCollection(apptGroup) {
        this.collection.reset();
        this.collection.addGroupCollection(apptGroup);
      },

      scrollToAppt(appointmentId, callback) {
        const view = this.findViewById(appointmentId);

        if (view) {
          this.scrollToElement(view.$el, callback);
        }
      },

      findViewById(appointmentId) {
        function hasMatchingAppointment(view) {
          return _.any(view.appointmentViews, function (apptView) {
            return apptView.model.id === parseInt(appointmentId, 10);
          });
        }

        return _.find(this.views, function (view) {
          if (view && view.appointmentViews) {
            return hasMatchingAppointment(view);
          }
          return _.any(view.apptViews, hasMatchingAppointment);
        });
      },

      scrollToElement($el, callback) {
        // Check if maybe the Appointment is first in a Group. If so, let's scroll to the group instead of the first
        // appt.
        const $groupEl = $el.closest('.appointment--package');
        let $element = $el;
        if ($groupEl.length && $groupEl.find('.appointment--item').first().is($element)) {
          $element = $groupEl;
        }

        const $scrollContent = this.$('.content-scroll');

        $scrollContent.animate(
          {
            scrollTop:
              $element.position().top + (parseInt($scrollContent.prop('scrollTop'), 10) || 0),
          },
          callback,
        );
      },

      scrollToAppointmentView(view, callback) {
        this.scrollToElement(view.$el, callback);
      },

      highlightAppt(appointmentIds) {
        const self = this;

        _.each(appointmentIds, function (appointmentId) {
          const view = self.findViewById(appointmentId);

          if (view) {
            view.$el.colorNotifyChange();
          }
        });
      },

      showError(message) {
        alert(message);
      },

      setMaxScrollHeight() {
        const $scrollContainer = this.$('.content-scroll');
        const scrollContainer = $scrollContainer[0];
        const $dialog = this.$el.closest('.react-dialog');
        const dialog = $dialog[0];
        const footer = $dialog.find('.dialog2--footer')[0];

        if (!dialog || !scrollContainer) {
          return;
        }

        const parentBox = dialog.getBoundingClientRect();
        const ownBox = scrollContainer.getBoundingClientRect();
        const footerHeight = footer ? footer.clientHeight : 48;

        const partAboveHeight = ownBox.top - parentBox.top;
        const maxHeight = window.innerHeight - 40 - partAboveHeight - footerHeight;

        $scrollContainer.css('max-height', maxHeight);
      },

      areAllViewsValid() {
        let allValid = true;
        _.each(this.views, (view) => {
          allValid = view.isValid() && allValid;
        });
        if (this.canShowCustomerContacts() && !inlineClient.isValid()) {
          allValid = false;
        }

        return allValid;
      },

      focusFirstError() {
        this.$('.error:first').focus();
      },

      removeViewsFromDOM() {
        // Clear the DOM. Doing this to ease up the Appointment.Item view cleanup.
        this.$('.js-appointments').empty();
        // Do cleanup on the views
        _.invoke(this.views, 'remove');
        this.views = [];
        // Remove all gap views
        _.invoke(this.gapViews, 'remove');
      },

      markRefunded({ checkoutId, cancellationId }) {
        this.collection.refund();
        this.checkouts
          .find((checkout) => checkout.getCheckoutId() === checkoutId)
          .markCanceled(cancellationId);
        this.renderCheckout();
        this.render({ disableFocusing: true });
      },

      refresh() {
        _.invoke(this.views, 'unloadmask');
        this.loadmask();
        // Refetch all the models and re-render everything
        this.renderPending = true;

        // If Walk-In appointment, need to set the model to it and fetch the model.
        if (this.singleAppointmentMode) {
          this.model = this.collection.at(0);
          this.fetchModel = true;
        }

        this.prepareModels();
      },

      handleFatalApiError() {
        window.alert(Wahanda.lang.shared.errors.generalReadError);
        this.close();
      },

      getLastAppointmentView() {
        return this.views[this.views.length - 1].getLastAppointmentView();
      },

      persistRebookingData() {
        if (!this.canShowCustomerContacts()) {
          return;
        }

        const maybeLastView = this.views[this.views.length - 1];
        const maybeOffer = maybeLastView.getSelectedOffer && maybeLastView.getSelectedOffer();
        const lastView =
          maybeOffer && maybeOffer.isServicePackage()
            ? maybeLastView
            : this.getLastAppointmentView();

        lastView.model.persistRebookingData();
      },

      loadmask() {
        this.$el.loadmask();
      },

      unloadmask() {
        this.$el.unloadmask();
      },

      // Events
      onCustomerSetWalkIn() {
        this.customerModel.set({}, { unset: true });
      },
      /**
       * Handle changing a Walk-In customer to a Customer.
       *
       * @param App.Models.Customer customer
       */
      onCustomerChosen(customer) {
        const appt = this.collection.at(0);
        this.options.customerId = customer.id;
        this.options.date = appt.get('appointmentDate');
        this.customerModel.set(customer.toJSON());
        this.collection.each((model) => model.set(customer.toAppointmentStructure()));
        this.options.heightChange(this.$el.height());

        // UX: After the customer is chosen, focus the first appointment's
        // first tabbable element.
        this.views[0].$el.find(':tabbable:first').focus();
      },

      // This is called from UDV.js/TabBase
      onDialogClose() {
        if (!this.isSaving) {
          _.invoke(this.views, 'onDismiss');
        }
        _.invoke(this.views, 'remove');
        inlineClient.destroy(this.$('.inline-client-create').get(0));
        App.ES6.Initializers.CustomerFormLinks(
          '',
          '',
          this.$('.customer-form-link').get(0),
        ).destroy();
        this.remove();
      },

      subsequentReschedule(data) {
        const changedModel = data.changedModel;
        const timeDiff = data.timeDiff;
        const newDate = data.date;

        const self = this;
        let forceFooterRendering = false;
        let found = false;
        const firstTimeIncrement = -timeDiff;

        const toReschedule = [];

        this.collection.any(function (model) {
          // Find the subsequent models.
          if (!found) {
            found = model === changedModel;
            if (
              !found &&
              !changedModel.id &&
              !model.id &&
              self.getViewForModel(model) === self.getViewForModel(changedModel)
            ) {
              // It's a workaround for unsaved Packages, as we only have one mock model in the collection
              // but inside there are as many models as appointments which will be created.
              found = true;
            }
            return;
          }

          if (
            model.id > 0 &&
            ((!model.isUnconfirmed() && !model.isConfirmed()) || !model.canBeRescheduled())
          ) {
            // Don't reschedule any other statuses than Unconfirmed and Confirmed.
            // And don't try to reschedule non-rescueduleable appointments.
            return;
          }

          // We want to reschedule if any of the will-be-rescheduled model's end time matches new ones start time
          let anyMatch = false;
          [changedModel].concat(toReschedule).forEach(function (prevModel) {
            const comparison = App.Models.Appointment.compareInTime(prevModel, model, {
              increment: changedModel === prevModel ? firstTimeIncrement : null,
              keepModelOrder: true,
            });

            if (comparison && comparison.oneAfterAnother) {
              anyMatch = true;
            }
          });

          // Check if this model does not have a time gap with the previous one.
          if (anyMatch) {
            toReschedule.push(model);
          }
        });

        if (toReschedule.length > 0) {
          // Change the start time accordingly
          toReschedule.forEach(function (model) {
            model.trigger('reschedule', {
              diff: timeDiff,
              date: newDate,
            });

            const modelView = self.getViewForModel(model);

            if (model.id > 0 && _.indexOf(self.changedForms, modelView) === -1) {
              // Mark form view as changed
              self.changedForms.push(modelView);
              forceFooterRendering = true;
            }
          });
        }

        this.onReschedule(changedModel, forceFooterRendering);
      },

      getViewForModel(model) {
        function getModels(views) {
          return _.pluck(views, 'model');
        }

        return _.find(this.views, function (view) {
          if (view.model === model) {
            return true;
          }
          if (view.appointmentViews && _.indexOf(getModels(view.appointmentViews), model) > -1) {
            return true;
          }
          if (view.apptViews && _.indexOf(getModels(view.apptViews), model) > -1) {
            return true;
          }
          return false;
        });
      },

      onReschedule(changedModel, forceFooterRendering) {
        if (this.collection.isSortOrderChanged()) {
          // Oops, the appointment order is changed!
          // Do it quick and dirty: remove all inner views, then render then again.
          this.loadmask();

          this.removeViewsFromDOM();

          this.options.highlightApptId = changedModel && changedModel.id;

          // Sort the collection.
          this.collection.sort();
          // Render the whole view again.
          this.render({ disableFocusing: true });

          // After rendering, the changedForms aren't the same views they were before.
          // Switch the views.
          const apptViews = this.views;
          const oldChangedForms = this.changedForms;
          this.changedForms = _.map(oldChangedForms, function (changedForm) {
            const model = changedForm.model;
            const collection = changedForm.collection;

            return _.find(apptViews, function (maybeView) {
              if (collection) {
                return maybeView.collection === collection;
              }
              return model.id === maybeView.model.id;
            });
          });
        } else {
          // Nothing serious. Just check the gaps.
          this.renderGaps();
          if (forceFooterRendering) {
            this.updateFooterButtons();
          }
        }
      },

      startRescheduleMode(targetModel, maybePosition, isWithinCancellation) {
        Wahanda.Appointment.enterReschedulingMode(
          targetModel,
          this.collection,
          maybePosition,
          isWithinCancellation,
        );
        this.close();
      },

      showRebookingConfirmation(confirmationCallback) {
        if (this.changedForms.length > 0) {
          App.ES6.Initializers.RebookingChangesDialog({
            onRebook: confirmationCallback,
          });
        } else {
          /* eslint-disable-next-line no-restricted-globals */
          this.startRebookingMode(event);
        }
      },
      startRebookingMode(event) {
        trackEvent('calendar', 'click', 'rebook-init', 'UDV');
        const startRebooking = function (maybeMousePos) {
          App.Collections.CustomerAppointments.enterRebooking(
            this.collection,
            this.menu.get('offersCollection'),
            maybeMousePos || {},
          );
        }.bind(this);

        if (this.changedForms.length > 0) {
          this.showRebookingConfirmation(
            function () {
              this.options.forceSave().then(function () {
                if (App.mainView.appointmentCalendar) {
                  // Problems with rebooking right away:
                  // 1. Calendar not refreshed
                  // 2. Updates are locked as the calendar is being fetched
                  App.mainView.appointmentCalendar.onNextLoaderHide(startRebooking);
                } else {
                  startRebooking();
                }
              });
            }.bind(this),
          );
        } else {
          startRebooking(event ? { x: event.clientX, y: event.clientY } : null);
          this.close();
        }
      },

      isCreatingAppointment() {
        const self = this;
        return _.every(self.views, (view) => view.model && view.model.id == null);
      },

      getViewsToSave() {
        // return only new or changed
        return _.filter(
          this.views,
          function (view) {
            return (
              // !view.model means "MultiServices Order". It must be confirmed.
              !view.model ||
              // All new models need saving
              !view.model.id ||
              // Confirm all unconfirmed
              view.model.isUnconfirmed() ||
              // Also save all changed forms
              _.indexOf(this.changedForms, view) > -1
            );
          },
          this,
        );
      },

      disableSaveButton() {
        this.$('.js-save').addClass('dialog2--button-processing');
      },

      enableSaveButton() {
        this.$('.js-save').removeClass('dialog2--button-processing');
      },

      isSaveDisabled() {
        return this.$('.js-save').hasClass('dialog2--button-processing');
      },

      close() {
        App.ES6.Initializers.State.change({
          'calendar-event-editor': null,
        });
      },

      // Saving
      /**
       * Save the Customer part of the UDV.
       *
       * @param Node duplicateCustomerTooltipTarget (optional) The tooltip target for duplicate
       *   client choice, if this save is done from the Checkout view.
       * @returns {Promise<Boolean>} True - meaning the saving can continue, or false -
       *      meaning the User cancelled the save when a duplicate client was found.
       * @throws if we get an network error. Should be caught by the calee.
       */

      async saveCustomer(duplicateCustomerTooltipTarget = null) {
        if (!this.canShowCustomerContacts()) {
          return true;
        }

        let duplicateTooltipTarget;
        if (duplicateCustomerTooltipTarget) {
          duplicateTooltipTarget = duplicateCustomerTooltipTarget;
        } else {
          duplicateTooltipTarget = this.$el
            .closest('.ui-dialog')
            .find('.dialog2--footer .dialog2--button')
            .first();
        }

        const customerData = inlineClient.getValues();
        if (customerData.id) {
          // Client is already saved.
          // This is valid in two cases:
          // 1. The customer was already set and we didn't change anything, or
          // 2. The customer was created in the Customer dialog, and we're using that.
          return true;
        }

        const firstAppt = this.collection.getFirstAppointmentByBookingActorImportance();
        if (!customerData.emailAddress && !customerData.phone) {
          // A walk-in. Use the name as `anonymousNote`, set the WalkIn data on all appointments.
          // We don't need to save the Customer in the WalkIn case as that
          // is handled by the backend automagically.
          const anonymousNote = customerData.name;
          if (firstAppt.isWalkin() && firstAppt.get('anonymousNote') === anonymousNote) {
            // We have the same walk-in name as we've already had.
            return true;
          }

          this.mediator.trigger(this.mediator.CUSTOMER_SET_WALKIN, {
            anonymousNote,
          });
          return true;
        }

        const check = await customerDuplicateCheck(customerData, duplicateTooltipTarget);
        // The save was cancelled in the Duplicate Client popup
        if (check.cancelled) {
          return false;
        }

        let customer;
        if (check.createNew) {
          // A new client is to be created
          customer = new App.Models.Customer(customerData);
          await customer.saveWithPromise();
        } else {
          // A customer was chosen or created. Or was already selected in Inline Client view.
          // Use that.
          customer = new App.Models.Customer(check.customerData);
        }

        const previousCustomerId = firstAppt.get('venueCustomerId');
        if (previousCustomerId !== customer.id) {
          // Set the customer on the views only if we're not re-using the existing customer.
          this.views.forEach((view) => view.onCustomerFound(customer));
        }

        return true;
      },

      async saveUpdatedAppointments(changedViews) {
        return new Promise((resolve, reject) => {
          const totalViews = changedViews.length;
          let saved = 0;
          let addToSaves;
          let onSaveFailed;

          function removeSaveListeners() {
            _.each(changedViews, (view) => {
              view.off('saved', addToSaves);
              view.off('save-failed', onSaveFailed);
            });
          }

          onSaveFailed = () => {
            _.each(this.views, (view) => this.stopListening(view));
            removeSaveListeners();
            reject();
          };

          addToSaves = () => {
            saved += 1;
            if (saved === totalViews) {
              removeSaveListeners();
              resolve();
            }
          };

          // Set up save event listeners
          _.each(changedViews, (view) => {
            view.on('saved', addToSaves);
            view.on('save-failed', onSaveFailed);
          });

          // We save the initial appointment first, so we
          // can associate the venueCustomerId with the rest of the appointments
          const firstView = changedViews[0];
          if (
            !isOrderView(firstView) &&
            firstView.appointmentViews.length > 0 &&
            !firstView.appointmentViews[0].model.get('venueCustomerId')
          ) {
            firstView.save(async () => {
              const model = firstView.appointmentViews[0].model;
              await model.fetchWithPromise();

              const venueCustomerId = model.get('venueCustomerId');
              // Update rest of the views with the new Customer Id
              const remainingViews = _.without(
                changedViews,
                _.findWhere(changedViews, { isFirstForToday: true }),
              );
              _.each(remainingViews, (itemView) => itemView.setVenueCustomerId(venueCustomerId));

              // Save the updated views
              _.invoke(remainingViews, 'save');

              addToSaves();
            });
          } else {
            _.invoke(changedViews, 'save');
          }
        });
      },

      saveCreatedAppointments: async function saveCreatedAppointments(models) {
        const self = this;

        _.invoke(self.views, 'onSaveClick');

        function getCustomer() {
          const customer = inlineClient.getValues();
          const hasAnonymousNote = !customer.emailAddress && !customer.phone;
          const venueCustomerId = customer.id
            ? customer.id
            : self.collection.getFirstAppointmentByBookingActorImportance().get('venueCustomerId');
          return {
            venueCustomerId,
            anonymousNote: hasAnonymousNote ? customer.name : undefined,
          };
        }

        function formatAppointmentModel(appointment) {
          return App.Models.Appointment.formatAppointmentModel(appointment);
        }

        function getAppointmentGroups(appointment) {
          return App.Models.Appointment.getAppointmentGroups(appointment);
        }

        function getAppointments(m) {
          return m
            .filter((model) => model instanceof App.Models.Appointment)
            .map((model) => formatAppointmentModel(model));
        }

        try {
          const data = {
            appointmentGroups: getAppointmentGroups(models),
            appointments: getAppointments(models),
            venueCustomerId: getCustomer().venueCustomerId,
            anonymousNote: getCustomer().anonymousNote,
          };

          await post(apiUrl('APPOINTMENTS'), data);
          App.trigger(Wahanda.Event.APPOINTMENT_SAVED);
        } catch (e) {
          this.showError(Wahanda.lang.calendar.appointments.errors.couldNotSave);
          throw e;
        }
      },

      // Existing client case: it will be saved, should be set on all the views already (TBC)
      // New inline client case: we need to save it, also doing the duplicate checks.
      // Walk in case: we have the functionality here.
      save(callback, { duplicateCustomerTooltipTarget } = {}) {
        if (this.isSaving) {
          console.log('Prevented a UDV save while another is already running');
          return Promise.reject();
        }

        // eslint-disable-next-line no-async-promise-executor
        const promise = new Promise(async (resolve, reject) => {
          if (this.canShowCustomerContacts()) {
            inlineClient.submit();
          }
          const allValid = this.areAllViewsValid();
          if (!allValid) {
            this.focusFirstError();

            const err = new Error('validation error');
            err.isValidationError = true;
            reject(err);
            return;
          }

          if (this.isSaveDisabled()) {
            reject();
            return;
          }

          this.disableSaveButton();

          try {
            // Make sure the inline client is saved first.
            // Saving the customer might mean that all appointments will need to be updated.
            const canContinue = await this.saveCustomer(duplicateCustomerTooltipTarget);
            if (!canContinue) {
              // Saving was prevented in the duplicate form. Re-enable UDV.
              this.enableSaveButton();
              resolve({ cancelled: true });
              return;
            }

            const changedViews = this.getViewsToSave();
            if (changedViews.length === 0) {
              // No views to save... Might be a bug. Or just nothing changed.
              callback('close');
              resolve({});
              return;
            }

            callback('hide');
            App.trigger(Wahanda.Event.APPOINTMENT_FORM_SUBMIT);

            // And then save all appointments
            if (this.isCreatingAppointment()) {
              await this.saveCreatedAppointments(
                this.views.map((view) => view.getObjectToSave(false, this.repeatModel)),
              );
            } else {
              await this.saveUpdatedAppointments(changedViews);
            }

            // All saving is done - do cleanups.
            this.persistRebookingData();
            callback('close');
            if (this.options.evoucherDialog) {
              this.options.evoucherDialog.close();
            }
            resolve({});
          } catch (e) {
            // Failed to save the client or the appointment.
            this.enableSaveButton();
            callback('show');
            App.trigger(Wahanda.Event.APPOINTMENT_FORM_ERRORS, e);
            console.error(e);
            reject(e);
          }
        });

        // Prevent duplicate saves
        this.isSaving = true;

        return promise.finally(() => {
          this.isSaving = false;
        });
      },

      onAddClick() {
        const allValid = this.areAllViewsValid();
        if (!allValid) {
          this.focusFirstError();
          return;
        }

        let lastView = this.views[0];
        let lastAppointmentEndTime = -1;
        _.each(this.views, function (view) {
          const apptEndTime = view.getAppointmentTimes(true).endTime;
          if (lastAppointmentEndTime < apptEndTime) {
            lastView = view;
            lastAppointmentEndTime = apptEndTime;
          }
        });

        const model = new App.Models.Appointment({
          appointmentDate: lastView.getLastModel().get('appointmentDate'),
          startTime: Wahanda.Time.toApiString(lastAppointmentEndTime),
          venueCustomerId: this.customerModel.id,
          consumerName: this.customerModel.get('name'),
          anonymousNote: lastView.getLastModel().get('anonymousNote') || null,
          employeeId: lastView.getLastModel().get('employeeId') || null,
        });

        this.collection.add(model);
        const view = this.addAppointmentView(model);

        this.updateFooterButtons();

        this.scrollToAppointmentView(view, function () {
          view.focusForm();
        });
        this.options.heightChange(this.$el.height());
      },

      onRemoveUnsavedView(model) {
        if (model.id) {
          // Don't remove existing models from DOM.
          // They will get re-rendered.
          return;
        }
        this.removeAppointmentView(model);
        this.triggerUnsavedAppointmentChangedEvent();
        this.notifyRendered();
        this.options.heightChange(this.$el.height());
      },

      onGroupConfirm() {
        this.close();
      },

      onGroupRejected() {
        this.close();
      },

      onGroupNoShow() {
        this.persistRebookingData();
      },

      onGroupCancelled() {
        this.persistRebookingData();
      },

      onGroupSaved() {
        this.persistRebookingData();
        this.close();
      },

      onNewPackageCreated() {
        this.close();
      },

      onAppointmentFormChange(view) {
        if (_.indexOf(this.changedForms, view) === -1) {
          this.changedForms.push(view);
        }
        this.updateFooterButtons();
        this.options.heightChange(this.$el.height());
      },

      onFullDayCheckout() {
        const openCheckoutDialog = function () {
          const FormClass = App.config.get('venue').pointOfSaleEnabled
            ? App.Views.Forms.POSDialog
            : App.Views.Forms.POSDialogLite;

          const dialog = new FormClass({
            collection: this.collection,
            mediator: this.mediator,
            saveAppointments: async (duplicateCustomerTooltipTarget) =>
              this.save(() => {}, duplicateCustomerTooltipTarget),
          });

          dialog.render();
          dialog.open();
        }.bind(this);

        const onDayClosed = function () {
          this.loadmask();
          Wahanda.Cache.posStatus().done(
            function (status) {
              this.posStatus = status;
              this.unloadmask();
            }.bind(this),
          );
          // There is no need to wait for posStatus to finish loading.
          // We can show the checkout dialog right away.
          openCheckoutDialog();
        }.bind(this);

        // This might return true, then no prev day is open
        // If returns false, it might go into closing a day - the callback
        // will be invoked afterwards.
        if (App.config.get('venue').pointOfSaleEnabled) {
          Wahanda.Cache.posStatus().done(function (status) {
            if (Wahanda.POSCheckoutCheck(status, onDayClosed)) {
              openCheckoutDialog();
            }
          });
        } else {
          openCheckoutDialog();
        }
      },

      // Called from UDV.js React Component when a drag of the dialog happens
      onPositionChange() {
        _.invoke(this.views, 'onPositionChange');
      },

      openCommissionsLinkClick() {
        AppointmentAnalytics.trackAppointmentCommissionsLinkClick();
      },
    },
    {
      /**
       * Open the Appointment dialog with an Appointment id.
       *
       * @param Number id Appointment id
       * @return App.Views.Forms.Appointment2
       */
      openCalendarEventEditor(apptData, top) {
        App.ES6.Initializers.State.change({
          'calendar-event-editor': {
            appointmentViewData: apptData,
            tab: 'appointment',
            top,
          },
        });
      },
      openById(id, top) {
        App.Views.Forms.Appointment2.openCalendarEventEditor(
          {
            model: new App.Models.Appointment({ id }),
          },
          top,
        );
      },

      openFromOrder(order) {
        App.Views.Forms.Appointment2.openCalendarEventEditor({
          orderSetupData: order,
        });
      },

      openByOrderId(orderId) {
        App.Views.Forms.Appointment2.openCalendarEventEditor({
          orderSetupOrderId: orderId,
        });
      },
    },
  );

  // Expose module contents globally
  App.Views.Forms.Appointment2 = AppointmentView;
})();
