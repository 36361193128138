import React from 'react';

interface ITabBaseProps extends React.HTMLAttributes<Element> {
  data?: {};
  customerData?: {};
  backboneClass?: (...args: any[]) => any;
  classes?: string;
  onRender?: (...args: any[]) => any;
  close?: (...args: any[]) => any;
  show?: (...args: any[]) => any;
  hide?: (...args: any[]) => any;
  updateButtons?: (...args: any[]) => any;
  heightChange?: (...args: any[]) => any;
  topChange?: (...args: any[]) => any;
  toggleDialogClosing?: (...args: any[]) => any;
  showConfirmDeletion?: (...args: any[]) => any;
  showConfirmNoShow?: (...args: any[]) => any;
  showCantDeleteDialog?: (...args: any[]) => any;
  showInitialDeleteDialog?: (...args: any[]) => any;
  toggleIgnoreKeyboardEvents?: (...args: any[]) => any;
}
type TabBaseState = {
  view?: any;
  onDialogClose?: any;
  onPositionChange?: any;
  onFullDayCheckout?: any;
  save?: any;
  del?: any;
};

export default class TabBase extends React.Component<ITabBaseProps, TabBaseState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'root' has no initializer and is not defi... Remove this comment to see the full error message
  root: HTMLDivElement;

  componentDidMount() {
    const BackboneView = this.props.backboneClass;
    [
      'updateButtons',
      'heightChange',
      'topChange',
      'toggleDialogClosing',
      'showConfirmDeletion',
      'showConfirmNoShow',
      'showCantDeleteDialog',
      'showInitialDeleteDialog',
      'forceSave',
      'toggleIgnoreKeyboardEvents',
      'onRender',
      'customerData',
    ].forEach((name) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.props.data[name] = this.props[name];
    });
    // @ts-expect-error ts-migrate(2350) FIXME: Only a void function can be called with the 'new' ... Remove this comment to see the full error message
    const instance = new BackboneView(this.props.data);
    instance.setElement(this.root);
    /*
     *  Assign the event handler before the event might be raisded :)
     */
    instance.on('rendered', this.props.onRender);
    /*
     *  The event might be raised any time after this call!
     */
    instance.render();
    // It's too dangerous to disable this right now as
    // I haven't been able to look at exactly how it works
    // TODO: Come back and work out why we're setting state inside of didMount
    this.setState({ view: instance }); //eslint-disable-line
  }

  shouldComponentUpdate() {
    // No need to re-render after the first render.
    return false;
  }

  componentWillUnmount() {
    if (this.state.view.onDialogClose) {
      this.state.view.onDialogClose();
    }
  }

  onPositionChange() {
    this.state.view.onPositionChange();
  }

  checkout() {
    this.state.view.onFullDayCheckout();
  }

  callback = (method) => {
    if (method === 'close') {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.close();
    } else if (method === 'hide') {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.hide();
    } else if (method === 'show') {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.show();
    }
  };

  save() {
    return this.state.view.save(this.callback);
  }

  del() {
    this.state.view.del(this.callback);
  }

  render() {
    return (
      <div
        ref={(root) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.root = root;
        }}
        className={this.props.classes}
      />
    );
  }
}
