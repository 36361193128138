import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from 'components/error/404';
import CampaignSelection from '../EmailCampaign/CampaignSelection/container';
import { CampaignPreview } from '../EmailCampaign/CampaignPreview/container';
import { CampaignSuccess } from '../EmailCampaign/CampaignSuccess';
import { BackToBusiness } from '../EmailCampaign/BackToBusiness/CampaignPreview';
import CampaignSummary from '../EmailCampaign/CampaignSummary/container';
import { ClientApp } from '../ClientApp';
import { FacebookBusinessExtension } from '../FacebookBusinessExtension';
import PartnerSite from '../PartnerSite/container';
import { AutomatedMessaging } from '../AutomatedMessaging';
import {
  GENERIC_MARKETING_CAMPAIGN_ID,
  APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID,
  VENUE_OPENING_CAMPAIGN,
} from '../EmailCampaign/CampaignList';
import PATHS from './paths';
import { ClientFormsCreate } from '../ClientForms/ClientFormsCreate/ClientFormsCreate';
import Wahanda from 'common/wahanda';
import { SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG } from '../ClientForms/FormTemplates/types';
import { Reconnect } from 'src/federation/Reconnect';
import { trackEvent } from 'common/analytics';

interface IRoutesProps extends React.HTMLAttributes<Element> {
  venueId: number | string;
  isEmailCampaignEnabled: boolean;
  isPromoOffersCampaignsEnabled: boolean;
  isClientAppEnabled: boolean;
  isEmailCampaignsAccessible: boolean;
  isAppForYourClientsCampaignEnabled: boolean;
  isPartnerSiteAccessible: boolean;
  isOpeningCampaignEnabled: boolean;
  isClientFormsAccessible: boolean;
  tab: string;
  referrer?: string;
}

export class Routes extends React.Component<IRoutesProps, {}> {
  public static defaultProps = {
    referrer: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.venueId !== undefined && this.props.venueId !== nextProps.venueId) {
      window.location.assign('/marketing');
    }
  }

  public setRootHash = () => {
    const { venueId, tab } = this.props;
    window.location.hash = `venue/${venueId}/${tab}`;
  };

  public emailCampaignRoutes = () => {
    const { isEmailCampaignEnabled, isPromoOffersCampaignsEnabled, referrer } = this.props;
    if (!isEmailCampaignEnabled && !isPromoOffersCampaignsEnabled) {
      return <Route exact path={PATHS.CAMPAIGN_SELECTION} component={CampaignSelection} />;
    }
    return [
      <Route
        exact
        key="cmpgn-selection-route"
        path={PATHS.CAMPAIGN_SELECTION}
        component={CampaignSelection}
      />,
      <Route
        exact
        key="cmpgn-preview-default"
        path={PATHS.CAMPAIGN_PREVIEW_DEFAULT}
        render={(props) => this.renderEmailPreviewDefault({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-preview-smart-discounts"
        path={PATHS.CAMPAIGN_PREVIEW_SMART_DISCOUNTS}
        render={(props) => this.renderSmartDiscountsEmailPreview({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-preview-service-on-sale"
        path={PATHS.CAMPAIGN_PREVIEW_SERVICE_ON_SALE}
        render={(props) => this.renderServiceOnSaleEmailPreview({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-summary-default"
        path={PATHS.CAMPAIGN_SUMMARY_DEFAULT}
        render={(props) => this.renderCampaignSummaryDefault({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-summary-smart-discounts"
        path={PATHS.CAMPAIGN_SUMMARY_SMART_DISCOUNTS}
        render={(props) => this.renderCampaignSummarySmartDiscounts({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-summary-service-on-sale"
        path={PATHS.CAMPAIGN_SUMMARY_SERVICE_ON_SALE}
        render={(props) => this.renderCampaignSummaryServiceOnSale({ ...props, referrer })}
      />,
      <Route
        exact
        key="cmpgn-success"
        path={PATHS.CAMPAIGN_SUCCESS}
        render={(props) => this.renderCampaignSuccess({ ...props, referrer })}
      />,
    ];
  };

  public clientAppRoutes = () => {
    const { isClientAppEnabled } = this.props;
    if (!isClientAppEnabled) {
      return null;
    }
    return <Route exact path={PATHS.CLIENT_APP_LANDING} component={ClientApp} />;
  };

  public partnerSiteRoutes = () => {
    const { isPartnerSiteAccessible } = this.props;
    if (!isPartnerSiteAccessible) {
      return null;
    }
    return <Route exact path={PATHS.PARTNER_SITE_SETTINGS} component={PartnerSite} />;
  };

  public fbeRoutes = () => {
    return <Route exact path={PATHS.FBE} component={FacebookBusinessExtension} />;
  };

  public automatedMessagingRoutes = () => {
    return <Route exact path={PATHS.AUTOMATED_MESSAGING} component={AutomatedMessaging} />;
  };

  public clientFormsRoutes = () => {
    const { isClientFormsAccessible } = this.props;
    if (!isClientFormsAccessible) {
      return null;
    }
    return (
      <Route
        exact
        path={PATHS.CLIENT_FORMS}
        component={() => {
          return (
            <Reconnect
              name="ConsultationFormsPage"
              props={{
                hasConsultationFormsList: Wahanda.Features.isEnabled(
                  SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG,
                ),
                notifyFn: Wahanda.ToastNotifications.sendMessage,
                legacyLang: Wahanda.lang,
                backboneEx: {
                  trackEvent,
                },
              }}
            />
          );
        }}
      />
    );
  };

  public clientFormsCreateRoutes = () => {
    const { isClientFormsAccessible } = this.props;
    if (
      !isClientFormsAccessible ||
      !Wahanda.Features.isEnabled(SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG)
    ) {
      return null;
    }

    return <Route exact path={PATHS.CLIENT_FORMS_CREATE} component={ClientFormsCreate} />;
  };

  public renderCampaignSuccess = (props) => <CampaignSuccess {...props} />;

  public renderEmailPreviewDefault = (props) => {
    const { templateId } = props.match.params;

    if (this.isTemplateProtected(templateId)) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    if (templateId === VENUE_OPENING_CAMPAIGN) {
      return this.renderVenueOpeningCampaignPreview(props);
    }
    return <CampaignPreview {...props} />;
  };

  public isTemplateProtected = (templateId) => {
    const { isEmailCampaignEnabled, isAppForYourClientsCampaignEnabled } = this.props;
    const protectedCampaigns = {
      [GENERIC_MARKETING_CAMPAIGN_ID]: !isEmailCampaignEnabled,
      [APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID]: !isAppForYourClientsCampaignEnabled,
    };
    return protectedCampaigns[templateId];
  };

  public renderVenueOpeningCampaignPreview = (props) => {
    const { isOpeningCampaignEnabled } = this.props;
    if (!isOpeningCampaignEnabled) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    return <BackToBusiness {...props} />;
  };

  public renderSmartDiscountsEmailPreview = (props) => {
    const { isPromoOffersCampaignsEnabled } = this.props;
    if (!isPromoOffersCampaignsEnabled) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    const discountRuleId = Number(props.match.params.selectedDiscountRuleId);
    return <CampaignPreview {...props} selectedDiscountRuleId={discountRuleId} />;
  };

  public renderServiceOnSaleEmailPreview = (props) => {
    const { isPromoOffersCampaignsEnabled } = this.props;
    if (!isPromoOffersCampaignsEnabled) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    const selectedTreatmentGroupId = Number(props.match.params.selectedTreatmentGroupId);
    return <CampaignPreview {...props} selectedTreatmentGroupId={selectedTreatmentGroupId} />;
  };

  public renderCampaignSummaryDefault = (props) => {
    const { templateId } = props.match.params;
    if (this.isTemplateProtected(templateId)) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    return <CampaignSummary {...props} />;
  };

  public renderCampaignSummarySmartDiscounts = (props) => {
    const { isPromoOffersCampaignsEnabled } = this.props;
    if (!isPromoOffersCampaignsEnabled) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    const discountRuleId = Number(props.match.params.selectedDiscountRuleId);
    return <CampaignSummary {...props} selectedDiscountRuleId={discountRuleId} />;
  };

  public renderCampaignSummaryServiceOnSale = (props) => {
    const { isPromoOffersCampaignsEnabled } = this.props;
    if (!isPromoOffersCampaignsEnabled) {
      this.setRootHash();
      return <CampaignSelection {...props} />;
    }
    const selectedTreatmentGroupId = Number(props.match.params.selectedTreatmentGroupId);
    return <CampaignSummary {...props} selectedTreatmentGroupId={selectedTreatmentGroupId} />;
  };

  public render() {
    const { isEmailCampaignsAccessible } = this.props;
    if (window.location.hash === '') {
      this.setRootHash();
    }
    return (
      <Switch>
        {isEmailCampaignsAccessible && this.emailCampaignRoutes()}
        {this.clientAppRoutes()}
        {this.partnerSiteRoutes()}
        {this.fbeRoutes()}
        {this.automatedMessagingRoutes()}
        {this.clientFormsRoutes()}
        {this.clientFormsCreateRoutes()}
        <Route component={Error404} />
      </Switch>
    );
  }
}
